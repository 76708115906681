import {
    FormControl,
    FormHelperText,
    InputLabel,
    Select as MuiSelect,
    SelectProps,
} from '@mui/material';
import React from 'react';
import useRandomId from '../helpers/useRandomId';

export interface CustomSelectProps<T> extends SelectProps<T> {
    onChangeValue: (newValue: T) => void;
    helperText?: string;
    error?: boolean;
}

export default function Select<T>({
    onChangeValue,
    helperText,
    error,
    ...rest
}: CustomSelectProps<T>): JSX.Element {
    const labelId = useRandomId();

    return (
        <FormControl fullWidth variant="filled">
            <InputLabel id={labelId} error={error} required={rest.required}>
                {rest.label}
            </InputLabel>

            <MuiSelect
                {...rest}
                variant="filled"
                labelId={labelId}
                value={rest.value}
                onChange={event => onChangeValue(event.target.value as T)}
                fullWidth
            >
                {rest.children}
            </MuiSelect>

            <FormHelperText error={error}>
                {helperText ?? ' '}
            </FormHelperText>
        </FormControl>
    );
}

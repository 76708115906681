import {
    Checkbox,
    FormControl,
    FormHelperText,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    SelectChangeEvent,
    SelectClasses,
    SelectProps,
} from '@mui/material';
import React, { useState, useEffect, ReactNode } from 'react';
import useRandomId from '../helpers/useRandomId';

export interface MultipleSelectProps<T extends Map<string, string>> extends SelectProps {
    values: T;
    onSetIds: (values: string[]) => void;
    helperText?: string;
    defaultSelectedValue?: string[];
    disabledValue?: string[];
    classNames?: {
        componentWrapper?: string;
        selectWrapper?: string;
        selectClasses?: Partial<SelectClasses>;
        item?: string;
        checkbox?: string;
        text?: string;
    };
}

function getMapKeyByValue(values: Map<string, string>, value: string): string {
    let result = '';

    values.forEach((val, key) => {
        if (val === value) {
            result = key;
        }
    });
    return result;
}

const DEFAULT_VALUE: string[] = [];

export default function MultipleSelect<T extends Map<string, string>>({
    helperText,
    values,
    onSetIds,
    defaultSelectedValue,
    disabledValue,
    classNames,
    ...rest
}: MultipleSelectProps<T>): JSX.Element {
    const labelId = useRandomId();

    const [selectValue, setSelectValue] = useState<string[]>(defaultSelectedValue ?? DEFAULT_VALUE);

    useEffect(() => {
        const ids = selectValue.map(value => getMapKeyByValue(values, value));

        onSetIds(ids);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectValue]);

    const handleChange = (event: SelectChangeEvent<typeof selectValue>): void => {
        const {
            target: { value },
        } = event;

        // On autofill we get a stringified value.
        const result = typeof value === 'string' ? value.split(',') : value;

        setSelectValue(result);
    };

    const renderValue = (selected: string[]): ReactNode => selected.join(', ');

    return (
        <FormControl fullWidth variant="filled" className={classNames?.componentWrapper}>
            <InputLabel id={labelId} error={rest.error} required={rest.required}>
                {rest.label}
            </InputLabel>

            <Select
                {...rest}
                className={classNames?.selectWrapper}
                classes={classNames?.selectClasses}
                defaultValue={DEFAULT_VALUE}
                variant="filled"
                labelId={labelId}
                multiple
                value={selectValue}
                renderValue={renderValue}
                onChange={handleChange}
                fullWidth
                displayEmpty
            >
                {Array.from(values).map(item => (
                    <MenuItem
                        key={item[0]}
                        className={classNames?.item}
                        value={item[1]}
                        disabled={disabledValue?.includes(item[1])}
                    >
                        <Checkbox
                            className={classNames?.checkbox}
                            checked={selectValue.includes(item[1])}
                        />
                        <ListItemText className={classNames?.text} primary={item[1]} />
                    </MenuItem>
                ))}
            </Select>

            <FormHelperText error={rest.error}>{helperText}</FormHelperText>
        </FormControl>
    );
}

import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import React from 'react';
import uuid from 'react-uuid';

export interface AutocompleteComponentProps<T>
    extends Omit<AutocompleteProps<T, false, false, false>, 'renderInput'> {
    label: string;
    options: T[];
    required?: boolean;
    isError?: boolean;
    helperText?: string;
}

export default function AutocompleteComponent<T>({
    label,
    options,
    required,
    isError,
    helperText,
    ...rest
}: AutocompleteComponentProps<T>): JSX.Element {
    return (
        <Autocomplete
            id={uuid()}
            autoComplete={false}
            {...rest}
            disablePortal
            renderInput={params => (
                <TextField
                    {...params}
                    label={label}
                    variant="filled"
                    fullWidth
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',
                    }}
                    required={required}
                    error={isError}
                    helperText={helperText ?? ''}
                />
            )}
            options={options}
        />
    );
}

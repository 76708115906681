import React, { FunctionComponent, useEffect, useState } from 'react';
import TextField from './TextField';

export interface UppercaseFieldProps {
    value: string;
    onChange?: () => void;
    onBlur: (newValue: string) => void;
    label: string;
    className?: string;
    required?: boolean;
    error?: boolean;
    helperText?: string;
    fixedHelperText?: boolean;
}

const UppercaseField: FunctionComponent<React.PropsWithChildren<UppercaseFieldProps>> = ({
    value,
    onChange,
    onBlur,
    label,
    className,
    required,
    error,
    helperText,
    fixedHelperText,
}) => {
    const [uncommittedValue, setUncommittedValue] = useState('');

    // TODO: From i18next
    const locale = 'en-GB';

    useEffect(() => {
        setUncommittedValue(value.toLocaleUpperCase(locale));
    }, [value]);

    return (
        <TextField
            value={uncommittedValue}
            onChange={event => {
                setUncommittedValue(event.target.value);
                onChange?.();
            } }
            onBlur={() => {
                const uppercase = uncommittedValue.toLocaleUpperCase(locale);

                setUncommittedValue(uppercase);
                onBlur(uppercase);
            } }
            label={label}
            className={className}
            required={required}
            error={error}
            helperText={helperText}
            fixedHelperText={fixedHelperText}
            variant="filled"
        />
    );
};

export default UppercaseField;

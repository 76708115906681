import { Chip } from '@mui/material';
import React, { FunctionComponent } from 'react';

export interface FilterChipProps {
    enabled: boolean;
    onChange: (enabled: boolean) => void;
    children: string;
    className?: string;
}

const FilterChip: FunctionComponent<React.PropsWithChildren<FilterChipProps>> = props => (
    <Chip
        variant={props.enabled ? 'filled' : 'outlined'}
        onClick={() => props.onChange(!props.enabled)}
        onDelete={props.enabled ? () => props.onChange(false) : undefined}
        label={props.children}
        color={props.enabled ? 'primary' : 'default'}
        className={props.className}
    />
);

export default FilterChip;

/* eslint-disable max-len */
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogProps, IconButton, useMediaQuery, useTheme } from '@mui/material';
import React, { FunctionComponent } from 'react';

import useModalStyles from './Modal.styles';

export interface ModalProps {
    open: boolean;
    onClose: () => void;
    maxWidth?: DialogProps['maxWidth'];
    fullScreen?: boolean;
}

const Modal: FunctionComponent<React.PropsWithChildren<ModalProps>> = ({ children, open, onClose, maxWidth, fullScreen }) => {
    const theme = useTheme();
    const modalClasses = useModalStyles();
    const isFullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            open={open}
            fullScreen={isFullScreen}
            onClose={onClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={modalClasses.root}
            maxWidth={maxWidth}
            classes={fullScreen ? { paper: modalClasses.paper } : {}}
        >
            <IconButton
                aria-label="close"
                className={modalClasses.closeButton}
                onClick={onClose}
                data-testid="close-button"
                size="large"
            >
                <CloseIcon />
            </IconButton>

            <div className={modalClasses.content}>{children}</div>
        </Dialog>
    );
};

export default Modal;

import ClearIcon from '@mui/icons-material/Clear';
import { IconButton, InputAdornment } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import formatISO from 'date-fns/formatISO';
import React, { FunctionComponent, useState } from 'react';
import TextField from './TextField';

export interface DateTimeFieldProps {
    label: string;
    value: Date | null;
    onChange: (newValue: Date | null) => void;
    className?: string;
    required?: boolean;
    helperText?: string;
    ampm?: boolean;
    utc?: boolean;
    error?: boolean;
    format?: string;
    disabled?: boolean;
    disableClear?: boolean;
}

const DateTimeField: FunctionComponent<React.PropsWithChildren<DateTimeFieldProps>> = ({
    label,
    value,
    onChange,
    className,
    required,
    ampm,
    helperText,
    utc,
    error,
    format,
    disabled,
    disableClear,
}) => {
    const [hovering, setHovering] = useState(false);
    const [focusing, setFocusing] = useState(false);

    return (
        <DateTimePicker
            value={value}
            onChange={newValue => {
                const result = (() => {
                    if (newValue === null) {
                        return null;
                    }

                    return utc ? new Date(formatISO(newValue)) : newValue;
                })();

                onChange(result);
            }}
            componentsProps={{
                actionBar: { actions: disableClear ? [] : ['clear'] },
            }}
            renderInput={props => (
                <TextField
                    {...props}
                    required={required}
                    helperText={helperText ?? ' '}
                    onMouseEnter={() => setHovering(true)}
                    onMouseLeave={() => setHovering(false)}
                    onFocus={() => setFocusing(true)}
                    onBlur={() => setFocusing(false)}
                    label={utc ? `${label} (UTC)` : label}
                    disabled={disabled}
                    error={error}
                    variant="filled"
                />
            )}
            ampm={ampm ?? false}
            disabled={disabled}
            className={className}
            InputProps={{
                endAdornment:
                    (hovering || focusing) && !disableClear && value !== null ? (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="Clear"
                                onClick={event => {
                                    event.stopPropagation();
                                    onChange(null);
                                }}
                                onMouseDown={event => event.preventDefault()}
                                size="large"
                            >
                                <ClearIcon />
                            </IconButton>
                        </InputAdornment>
                    ) : null,
            }}
            inputFormat={format ?? 'dd/MM/yyyy HH:mm'}
        />
    );
};

export default DateTimeField;

/* eslint-disable @typescript-eslint/naming-convention */
import { FilledTextFieldProps as MuiTextFieldProps } from '@mui/material';
import React, { FunctionComponent, useEffect, useState } from 'react';
import TextField from './TextField';

function getDecimalSeparator(locale: string): string {
    return 1.1.toLocaleString(locale).substring(1, 2);
}

function sanitizeInput(input: string, locale: string): number | null {
    const periodIndex = input.indexOf(getDecimalSeparator(locale));

    const inputWithoutDecimals
        = periodIndex === -1 ? input : input.substring(0, periodIndex + 1);

    const onlyNumbers = inputWithoutDecimals.replace(/\D/g, '');

    return onlyNumbers.length === 0 ? null : parseInt(onlyNumbers);
}

export type IntegerFieldProps = Pick<MuiTextFieldProps, 'InputProps'> & {
    value: number | null;
    onBlur: (newValue: number | null) => void;
    label: string;
    className?: string;
    required?: boolean;
    error?: boolean;
    helperText?: string;
    fixedHelperText?: boolean;
};

const IntegerField: FunctionComponent<React.PropsWithChildren<IntegerFieldProps>> = ({
    value,
    onBlur,
    label,
    className,
    required,
    error,
    helperText,
    fixedHelperText,
    InputProps,
}) => {
    const [focused, setFocused] = useState(false);
    const [stringValue, setStringValue] = useState('');

    // TODO: From i18next
    const locale = 'en-GB';

    useEffect(() => {
        setStringValue(value === null ? '' : value.toString());
    }, [value]);

    const inputValue = (() => {
        if (focused) {
            return stringValue;
        }

        if (value === null) {
            return '';
        }

        // Adds thousands separator
        return value.toLocaleString(locale);
    })();

    return (
        <TextField
            value={inputValue}
            onChange={event => setStringValue(event.target.value)}
            onFocus={() => setFocused(true)}
            onBlur={() => {
                const sanitized = sanitizeInput(stringValue, locale);

                setStringValue(sanitized ? sanitized.toString() : '');
                onBlur(sanitized);
                setFocused(false);
            }}
            label={label}
            className={className}
            required={required}
            error={error}
            helperText={helperText}
            fixedHelperText={fixedHelperText}
            InputProps={InputProps}
            variant="filled"
        />
    );
};

export default IntegerField;
